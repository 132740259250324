.bgStyles{
    background-color: black;
    display: flex;
    justify-content  : center;
    width: 100%;
    height:185vh
}

.bgStyles2{
    height:160vh;
    width:500px
}

@media screen and (max-width: 550px){
    .bgStyles{
        background-color: black;
        display: flex;
        justify-content  : center;
        width: 100%;
        height:154vh
    }
    
    .bgStyles2{
        height:155vh;
        width:500px
    }
}